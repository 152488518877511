import React from 'react';
import ReactDOM from 'react-dom';
// test

import App from './App';
import './assets/css/about.css';
import './assets/css/bootstrap.min.css';
import './assets/css/button.css';
import './assets/css/calltoaction.css';
import './assets/css/clients.css';
import './assets/css/contact.css';
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/font-awesome.min.css';
import './assets/css/font.css';
import './assets/css/footer.css';
import './assets/css/formpage.css';
import './assets/css/header.css';
import './assets/css/headtitle.css';
import './assets/css/hero.css';
import './assets/css/heroaida.css';
import './assets/css/magnific-popup.css';
import './assets/css/magnific.dark.css';
import './assets/css/magnific.rtl.css';
import './assets/css/main.css';
import './assets/css/numbers.css';
import './assets/css/productspage.css';
import './assets/css/services.css';
import './assets/css/style.css';
import './assets/css/testimonials.css';
import './assets/css/variables.css';
import './index.scss';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
