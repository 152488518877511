import React, { lazy, Suspense } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollToTop from './components/Helper/ScrollToTop';

const Error = lazy(() => import('./components/Error'));
// const Contact = lazy(() => import('./pages/contact'));
// const ContactEn = lazy(() => import('./pages/contactEn'));
// const HomeEn = lazy(() => import('./pages/HomeEn'));
// const Home = lazy(() => import('./pages'));
const HomeAida = lazy(() => import('./pages/aida'));
const Form = lazy(() => import('./pages/Form'));

function Routes() {
    return (
        <div className="appie-visible active">
            <Router>
                <Suspense
                    fallback={
                        <div className="appie-loader active">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="var(--primary-color)"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible
                            />
                        </div>
                    }
                >
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeAida} />
                            <Route exact path="/form" component={Form} />

                            {/* <Route exact path="/ar" component={Home} />
                            <Route exact path="/en" component={HomeEn} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/contact/en" component={ContactEn} /> */}

                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Suspense>
            </Router>
        </div>
    );
}

export default Routes;
